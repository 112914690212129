import { useEffect, useState } from 'react';
import { Container, Text } from 'ui';
import { ClassName } from 'core';
import cls from 'classnames';
import { formatDuration, intervalToDuration } from 'date-fns';
import React from 'react';
import { isUndefined } from 'lodash-es';

interface SecurityLockCountdownProps extends ClassName {
  end: Date | null;
}

const formatToTwoDigits = (num: number) => num.toString().padStart(2, '0');

export const SecurityLockCountdown = React.memo(
  ({ end, className }: SecurityLockCountdownProps) => {
    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
      if (!end) {
        return;
      }

      const calculateTimeLeft = () => {
        const start = new Date().getTime();
        const { hours, minutes, seconds } = intervalToDuration({ start, end });

        if (isUndefined(hours) || isUndefined(minutes) || isUndefined(seconds)) {
          return;
        }

        if (hours === 0 && minutes > 0) {
          setTimeLeft(formatDuration({ minutes }));
          return;
        }

        if (hours === 0 && minutes === 0 && seconds > 0) {
          setTimeLeft(formatDuration({ minutes, seconds }));
          return;
        }

        if (hours === 0 && minutes === 0 && seconds === 0) {
          setTimeLeft('');
          return;
        }

        setTimeLeft(`${formatToTwoDigits(hours)}h ${formatToTwoDigits(minutes)}m`);
      };

      calculateTimeLeft();
      const interval = setInterval(calculateTimeLeft, 1000);
      return () => {
        clearInterval(interval);
      };
    }, [end]);

    return (
      <Container className={cls(className)}>
        <Text type="data-m">{timeLeft}</Text>
      </Container>
    );
  },
);

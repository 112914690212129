import { ApiPromise } from '@polkadot/api';

export const getBlock = (api: ApiPromise | null) => async () => {
  if (!api) {
    return null;
  }

  const blockData = await api.rpc.chain.getBlock();

  return blockData.block.header;
};

import { useQuery } from '@tanstack/react-query';
import { useTransaction } from '../../../transaction/useTransaction';
import { getAllPools } from '../services/AllPoolsService';
import { transformAllPools } from '../transformers/AllPoolsTransformers';
import { useTokenBucketQuery } from '../../../token/bucket/query/useTokenBucketQuery';
import { useNativeTokenQuery } from '../../../token';
import { QUERY_REFETCH_INTERVAL, useApi } from '../../../../services';
import { usePromotedPoolsMetadataQuery } from '../../promoted/metadata/query/usePromotedPoolsMetadataQuery';

export const useAllPoolsQuery = () => {
  const api = useApi();
  const { finalisedTxCount } = useTransaction();
  const { isFirstPoolTokenLeader } = useTokenBucketQuery();
  const {
    nativeAssetsQuery: { data: assets },
  } = useNativeTokenQuery();
  const { metadataQuery } = usePromotedPoolsMetadataQuery();

  const promotedPoolsData = metadataQuery.data?.promotedPoolWeightData;
  const allPoolsQuery = useQuery(['all-pools', finalisedTxCount], getAllPools(api), {
    select: transformAllPools(assets, isFirstPoolTokenLeader, promotedPoolsData),
    enabled: !!assets && !!api,
    refetchInterval: QUERY_REFETCH_INTERVAL.allPools,
    keepPreviousData: true,
  });

  return { allPoolsQuery };
};

import { ApiPromise } from '@polkadot/api';
import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryOptional } from '../../../services';

interface FeeLockMetadata {
  periodLength: string;
  feeLockAmount: string;
  swapValueThreshold: string;
  whitelistedTokens: number[];
}

export const getFeeMetadata = (api: ApiPromise | null) => async () => {
  if (!api) {
    return null;
  }

  const feeLock = (
    await api.query.feeLock.feeLockMetadata()
  ).toJSON() as unknown as FeeLockMetadata;

  return feeLock;
};

export type GetAccountFeeLockMetadataQueryKey = [string, QueryOptional<string>, number];

export const getAccountFeeLockMetadata =
  (api: ApiPromise | null) =>
  async ({ queryKey: [, address] }: QueryFunctionContext<GetAccountFeeLockMetadataQueryKey>) => {
    if (!api || !address) {
      return null;
    }

    const metadata = await api.query.feeLock.accountFeeLockData(address);

    return metadata;
  };

import { useAccountFeeMetadataQuery, useBlock, useFeeMetadataQuery, useMangataAsset } from 'core';
import { BN_ZERO, fromBN } from 'gasp-sdk';
import { Container, Text, useMultiHover } from 'ui';
import { ReactComponent as LockIcon } from 'icons/security-lock.svg';
import { BN } from '@polkadot/util';
import { useEffect, useMemo, useRef, useState } from 'react';
import { animated, useTransition } from '@react-spring/web';
import { SecurityLockCountdown } from './SecurityLockCountdown';

const BLOCK_TIME = 6000;

export const NativeTokenWidgetLockInfo = () => {
  const [isDescVisible, setIsDescVisible] = useState(false);
  const endTimeRef = useRef<Date | null>(null);

  const { mangataAsset } = useMangataAsset();

  const {
    accountFeeMetadataQuery: { data: accountFeeLockMetadata },
  } = useAccountFeeMetadataQuery();

  const {
    feeMetadataQuery: { data: lockMetadata },
  } = useFeeMetadataQuery();

  const {
    getLatestBlockQuery: { data: block },
  } = useBlock();

  const contentRef = useRef<HTMLDivElement>(null);

  const isHovering = useMultiHover([contentRef.current]);

  const locked = accountFeeLockMetadata?.totalFeeLockAmount.toBn() ?? BN_ZERO;

  const lockEndTime = useMemo(() => {
    if (!block || !lockMetadata || !accountFeeLockMetadata) {
      return null;
    }

    const releaseBlock = accountFeeLockMetadata.lastFeeLockBlock.add(
      new BN(lockMetadata.periodLength),
    );

    const remainingBlocks = releaseBlock.sub(block.number.toBn());

    const remainingTime = remainingBlocks.muln(BLOCK_TIME);
    const lockEndTime = new Date(new Date().getTime() + remainingTime.toNumber());

    return lockEndTime;
  }, [accountFeeLockMetadata, block, lockMetadata]);

  const isReleasing = Math.max(0, (lockEndTime?.getTime() ?? 0) - new Date().getTime()) < 1500;

  const descVisibilitySpring = useTransition(isDescVisible, {
    from: { opacity: 0, maxHeight: 0 },
    enter: { opacity: 1, maxHeight: 150 },
    leave: { opacity: 0, maxHeight: 0 },
  });

  const toggleDesc = () => setIsDescVisible(!isDescVisible);

  useEffect(() => {
    if (lockEndTime && !endTimeRef.current) {
      endTimeRef.current = lockEndTime;
    }
  }, [lockEndTime]);

  useEffect(() => {
    if (!isHovering) {
      setIsDescVisible(false);
    }
  }, [isHovering]);

  if (locked.isZero()) {
    return null;
  }

  return (
    <Container
      className="mx-5 rounded-xl bg-soft hover:bg-hover transition-all cursor-pointer"
      onClick={toggleDesc}
      ref={contentRef}
    >
      <Container className="p-4" column>
        <Container alignItems="center">
          <div className="mr-2">
            <LockIcon className="w-[28px] h-auto stroke-neutral" />
          </div>
          <Container column>
            <Container>
              <Text type="data-l">+{fromBN(locked, mangataAsset?.decimals)}</Text>
              <Text type="data-l" id="common.mainToken" className="ml-1" />
            </Container>
            <Container className="mt-0.5" alignItems="baseline">
              <Text
                className="mr-1.5"
                type="body-s"
                color="secondary"
                id={
                  isReleasing
                    ? 'nativeTokenWidget.securityLock.releaseInfo.inProgress'
                    : 'nativeTokenWidget.securityLock.releaseInfo'
                }
              />
              {!isReleasing && <SecurityLockCountdown end={endTimeRef.current} />}
            </Container>
          </Container>
        </Container>
        {descVisibilitySpring((style, item) => {
          return (
            item && (
              <animated.div style={style} className="overflow-hidden">
                <Container column>
                  <Text
                    className="mt-4"
                    color="secondary"
                    id="nativeTokenWidget.securityLock.releaseInfo.desc"
                  />
                  <Text
                    className="mt-4"
                    color="secondary"
                    type="body-m-bold"
                    id="nativeTokenWidget.securityLock.releaseInfo.desc2"
                  />
                </Container>
              </animated.div>
            )
          );
        })}
      </Container>
    </Container>
  );
};

import { Container, Text, TokenIcon } from 'ui';
import { ReactComponent as ArrowRight } from 'icons/arrow-right.svg';
import { ReactComponent as UniswapLogo } from '../assets/uniswap.svg';
import { EnvConfig, useMangataAsset } from 'core';

const NON_TRADABILITY_LINK = 'https://blog.gasp.xyz/the-gasp-alpha-launch/';

export const NativeTokenWidgetGetGasp = () => {
  const { mangataAsset } = useMangataAsset();

  const uniswapWidget = (
    <a
      href={EnvConfig.TOKEN_BUY_URL}
      target="_blank"
      rel="noopener noreferrer"
      className="w-full mt-4 mb-3"
    >
      <Container fullWidth className="rounded-xl bg-soft hover:bg-hover p-4 transition-colors">
        <Container column className="mr-4" fullWidth>
          <Container fullWidth alignItems="center">
            <Text id="nativeTokenWidget.getGasp.uniswap.title" type="title-3" />
            <div className="ml-1">
              <UniswapLogo className="w-[90px] h-[25px] fill-neutral" />
            </div>
          </Container>
        </Container>
        <div>
          <ArrowRight className="stroke-secondary w-5 h-auto" />
        </div>
      </Container>
    </a>
  );

  return (
    <Container fullWidth column className="px-5 mt-2 border-t border-default">
      <Container alignItems="center" className="mt-5">
        <Text id="nativeTokenWidget.getGasp.title" type="title-2" />
        <TokenIcon token={mangataAsset} className="mx-1.5" />
        <Text id="common.mainToken" type="title-2" />
      </Container>
      <div className="mt-2">
        <Text id="nativetokenWidget.getGasp.notice.prefix" color="secondary" />
        <Text id="nativetokenWidget.getGasp.notice.content" color="secondary" className="mx-1" />
        <a href={NON_TRADABILITY_LINK} rel="noopener noreferrer" target="_blank">
          <Text
            id="nativetokenWidget.getGasp.notice.suffix"
            color="secondary"
            className="underline"
          />
        </a>
      </div>
      {uniswapWidget}
    </Container>
  );
};

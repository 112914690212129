import { QueryFunctionContext } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { QueryOptional } from '../../../../../../services';
import { EnvConfig } from '../../../../../../envConfig';

export enum TxTrackingStatus {
  Initiated = 'Initiated',
  Pending_L1 = 'PendingOnL1',
  Submitted_L2 = 'SubmittedToL2',
  Pending_L2 = 'PendingOnL2',
  Batched_L1 = 'BatchedForL1',
  Processed = 'Processed',
}

export interface TxTrackingByAddressItem {
  requestId: string | null;
  txHash: string;
  address: string;
  created: number;
  updated: number;
  status: TxTrackingStatus;
  type: string;
  amount: string;
  asset_chainId: string;
  asset_address: string;
}

const axiosConfig: AxiosRequestConfig<TxTrackingByAddressResponse> = {
  validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
};

export interface TxTrackingByAddressResponse {
  transactions: TxTrackingByAddressItem[];
}

export type TxTrackingByAddressParams = [string, QueryOptional<string>];

export const fetchTxTrackingList = async ({
  queryKey,
}: QueryFunctionContext<TxTrackingByAddressParams>) => {
  const [, address] = queryKey;

  if (!address) {
    return null;
  }

  const { data: depositData } = await axios.get<TxTrackingByAddressResponse>(
    `${EnvConfig.STASH_URL}/tracing/type/deposit/tx/listByAddress/${address}`,
    axiosConfig,
  );

  const { data: withdrawData } = await axios.get<TxTrackingByAddressResponse>(
    `${EnvConfig.STASH_URL}/tracing/type/withdrawal/tx/listByAddress/${address}`,
    axiosConfig,
  );

  const data = [...(depositData?.transactions ?? []), ...(withdrawData?.transactions ?? [])];

  return data;
};

import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../../services/sdk/useApi';
import { getAccountFeeLockMetadata } from './FeeMetadataService';
import { DEFAULT_QUERY_OPTIONS } from '../../../services';
import { useAccounts } from '../../account';
import { useTransaction } from '../../transaction';

export const useAccountFeeMetadataQuery = () => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { finalisedTxCount } = useTransaction();

  const accountFeeMetadataQuery = useQuery(
    ['account-fee-metadata', selectedAccount?.address, finalisedTxCount],
    getAccountFeeLockMetadata(api),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!api && !!selectedAccount,
      refetchInterval: 6000,
    },
  );

  return { accountFeeMetadataQuery };
};

import cls from 'classnames';
import React, { forwardRef } from 'react';
import { PrimitiveType, useIntl } from 'react-intl';

import { Children, ClassName, TestId } from '../../types';

export type TextElementType = HTMLParagraphElement | HTMLSpanElement | HTMLHeadingElement;

type TextWeight = 'normal' | 'light' | 'semibold' | 'bold' | 'black';
type TextElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

type TextType =
  | 'display'
  | 'display-2'
  | 'title-1'
  | 'title-2'
  | 'title-3'
  | 'title-4'
  | 'title-5'
  | 'data-input'
  | 'data-l'
  | 'data-m'
  | 'data-s'
  | 'body-l'
  | 'body-m'
  | 'body-m-bold'
  | 'body-s'
  | 'body-tiny'
  | 'label-bold'
  | 'label'
  | 'label-s'
  | 'button-l'
  | 'button-m'
  | 'button-s'
  | 'button-link';

export type TextColor =
  | 'neutral'
  | 'secondary'
  | 'inverted'
  | 'dimmed'
  | 'highlight'
  | 'accent'
  | 'alert'
  | 'inverted-dimmed'
  | 'positive'
  | 'attention'
  | 'on-highlight'
  | 'none';

interface TextWithChildren extends Children {
  id?: never;
  values?: never;
}

interface TextWithTranslation {
  children?: never;
  values?: Record<string, PrimitiveType | JSX.Element>;
  id: string;
}

type TextDisplayType = TextWithChildren | TextWithTranslation;

interface BaseTextProps extends ClassName, TestId {
  element?: TextElement;
  type?: TextType;
  color?: TextColor;
  weight?: TextWeight;
  uppercase?: boolean;
}

type TextProps = BaseTextProps & TextDisplayType;

const TYPES: Record<TextType, string> = {
  display: 'font-display',
  'display-2': 'font-display-2',
  'title-1': 'font-title',
  'title-2': 'font-title-2',
  'title-3': 'font-title-3',
  'title-4': 'font-title-4',
  'title-5': 'font-title-5',
  'data-input': 'font-data-input',
  'data-l': 'font-data-l',
  'data-m': 'font-data-m',
  'data-s': 'font-data-s',
  'body-l': 'font-body-l',
  'body-m': 'font-body-m',
  'body-m-bold': 'font-body-m-bold',
  'body-s': 'font-body-s',
  'body-tiny': 'font-body-tiny',
  'label-bold': 'font-label-bold',
  label: 'font-label',
  'label-s': 'font-label-s',
  'button-l': 'font-button-l',
  'button-m': 'font-button-m',
  'button-s': 'font-button-s',
  'button-link': 'font-button-link',
};

const COLORS: Record<TextColor, string> = {
  neutral: 'text-neutral',
  secondary: 'text-secondary',
  inverted: 'text-inverted',
  dimmed: 'text-dimmed',
  highlight: 'text-highlight',
  accent: 'text-accent',
  alert: 'text-alert',
  'inverted-dimmed': 'text-inverted-dimmed',
  positive: 'text-positive',
  attention: 'text-attention',
  'on-highlight': 'text-on-highlight',
  none: '',
};

const WEIGHTS = {
  normal: 'font-normal',
  light: 'font-light',
  semibold: 'font-semibold',
  bold: 'font-bold',
  black: 'font-black',
};

export const Text = forwardRef<TextElementType, TextProps>(
  (
    {
      element = 'span',
      type = 'body-m',
      color = 'neutral',
      children,
      id,
      className,
      weight,
      'data-testid': testId,
      values,
      uppercase,
    }: TextProps,
    ref,
  ) => {
    const intl = useIntl();

    const component = React.createElement(
      element,
      {
        ref,
        className: cls(
          'transition-all',
          TYPES[type],
          color && COLORS[color],
          weight && WEIGHTS[weight],
          uppercase && 'uppercase',
          className,
        ),
        'data-testid': testId,
      },
      id ? intl.formatMessage({ id }, values) : children,
    );

    return component;
  },
);

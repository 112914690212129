import { useQuery } from '@tanstack/react-query';
import { getBlock } from '../services/BlockService';
import { DEFAULT_QUERY_OPTIONS, useApi } from '../../../services';
import { useTransaction } from '../../transaction';

export const useBlockQuery = () => {
  const api = useApi();
  const { finalisedTxCount } = useTransaction();

  const getLatestBlockQuery = useQuery(['get-block', finalisedTxCount], getBlock(api), {
    ...DEFAULT_QUERY_OPTIONS,
    enabled: !!api,
    refetchInterval: 6000,
    cacheTime: 0,
  });

  return {
    getLatestBlockQuery,
  };
};

import cls from 'classnames';
import { Container } from '../../components/Container/Container';
import { useLayoutSize } from '../../hooks';
import { createContext, ReactNode, RefObject, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

export enum LayoutSize {
  Narrow = 'narrow',
  Wide = 'wide',
}

export const AppContainerRefContext = createContext<RefObject<HTMLDivElement> | null>(null);

export const LAYOUT_SIZE_MAP = {
  [LayoutSize.Narrow]: 'w-full sm:w-[480px] sm:min-w-[480px]',
  [LayoutSize.Wide]: 'w-full xl:max-w-[840px]',
};

export interface ContainerProps {
  children: ReactNode;
  isSidebarOpen: boolean;
}

export const AppContainer = ({ children, isSidebarOpen }: ContainerProps) => {
  const { layoutSize } = useLayoutSize();
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [pathname]);

  return (
    <AppContainerRefContext.Provider value={ref}>
      <Container
        fullWidth
        ref={ref}
        className={cls(
          'h-full w-full sm:min-w-[480px] transition-all duration-500 ease-in-out p-6',
          'relative z-10 overflow-x-hidden no-scrollbar left-0 justify-stretch sm:justify-center',
          'max-h-[100dvh] overflow-y-auto',
          !isSidebarOpen && 'xl+:left-[-100px]',
        )}
      >
        <div className={cls('h-full', LAYOUT_SIZE_MAP[layoutSize])}>{children}</div>
      </Container>
    </AppContainerRefContext.Provider>
  );
};

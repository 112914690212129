import { fromBN } from '@mangata-finance/sdk';
import { useInternalBalance, useMangataAsset } from 'core';
import { Container, FormatAmount, Text, TokenIcon } from 'ui';

export const NativeTokenWidgetBalance = () => {
  const { mgxBalance } = useInternalBalance();
  const { mangataAsset } = useMangataAsset();

  const free = mgxBalance && mangataAsset ? fromBN(mgxBalance.free, mangataAsset.decimals) : '0';

  return (
    <Container column fullWidth className="bg-default px-5 py-4 pb-0 rounded-t-xl">
      <Text id="nativeTokenWidget.balance" type="data-m" color="secondary" />
      <Container alignItems="center" className="mt-3">
        {mangataAsset && <TokenIcon size="xl" token={mangataAsset} className="mr-2" />}
        <Text type="title-1">
          <FormatAmount
            value={free}
            options={{ maxChars: 10, precision: 0, nonZeroPrecision: false }}
          />
          <Text type="title-1"> {mangataAsset?.symbol}</Text>
        </Text>
      </Container>

      <Text color="secondary" type="title-4" className="mt-1"></Text>
    </Container>
  );
};
